<div>
  <h5 translate>Backup code</h5>
  <p translate>
    A backup code can be used to disable Multifactor authentication whenever you permanently lose access to your
    multifactor device (e.g. a lost, stolen or broken phone). Make sure you keep your backup code in a safe place to
    avoid the possibility of getting locked out of your account.
  </p>

  <div *ngIf="backupCode">
    <button
      [ladda]="isLoading"
      [sbTooltip]="'Refresh backup code' | translate"
      (click)="refreshBackupCode()"
      type="button"
      class="btn btn-primary anonymous__form-submit float-right"
      translate
    >
      <icon class="si d-flex hidden-md-up" svgIcon="repeat"></icon>
      <icon class="sh-tl__btn-icon" svgIcon="repeat"></icon>
    </button>
    <strong class="block text-center text-2xl">{{ backupCode }}</strong>
  </div>

  <modal-field *ngIf="!backupCode && !password" [label]="'Password' | translate" [control]="form.get('password')">
    <input
      class="form-modal__input-short"
      type="password"
      [placeholder]="'Password' | translate"
      [formControl]="form.get('password')"
    />
  </modal-field>
</div>
