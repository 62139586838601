import { NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { validateFormFields } from '@app/forms/form.helper';
import { TranslateModule } from '@ngx-translate/core';

import { TooltipDirective } from '../../../../../libs/tooltip/src';
import { IconComponent } from '../../../+authenticated/shared/icon.component';
import { Features } from '../../../enums';
import { ModalFieldComponent } from '../../../forms/modal-field.component';
import { MfaService } from '../../../reducers/mfa/mfa.service';
import { LaddaComponent } from '../../ladda/ladda.component';

@Component({
  selector: 'configure-backup-code',
  templateUrl: './configure-backup-code.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    NgIf,
    LaddaComponent,
    TooltipDirective,
    ReactiveFormsModule,
    IconComponent,
    ModalFieldComponent,
  ],
})
export class ConfigureBackupCodeComponent implements OnInit {
  @Input()
  public form: UntypedFormGroup;

  @Input()
  public backupCode: string;

  @Input()
  public isLoading: boolean;

  public Features = Features;

  public password: string;

  public constructor(private mfaService: MfaService) {}

  public ngOnInit() {
    this.password = this.mfaService.password;
    if (!this.password) {
      return;
    }

    const getBackupCodeData = {
      password: this.password,
    };

    void this.mfaService.getBackupCode(getBackupCodeData).subscribe({
      next: (response) => {
        this.backupCode = response.backup_code;
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  public submit() {
    validateFormFields(this.form);
  }

  public refreshBackupCode() {
    this.isLoading = true;
    void this.mfaService.refreshBackupCode().subscribe({
      next: (response) => {
        this.isLoading = false;
        this.backupCode = response.backup_code;
      },
      error: (error) => {
        this.isLoading = false;
        console.error(error);
      },
    });
  }
}
